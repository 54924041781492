import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-extension-layout',
  templateUrl: './extension-layout.component.html',
  styleUrls:['./extension-layout.component.scss']
})

export class ExtensionLayoutComponent implements OnInit {


  showLayout: boolean = true;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.firstChild?.data.subscribe((data) => {
      this.showLayout = !data['noLayout'];
    });
  }

}
