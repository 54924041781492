<footer class="footer">
    <div class="footer-content justify-content-between">
        <span>
            <a href="https://www.ultraviewer.net/vi/download.html" target="_blank" class="text-gray m-r-15 huongdansudung">
                <span nz-icon nzType="download" nzTheme="outline"></span>
                Tải Ultraview
            </a>
            <a href="https://docs.sandbox.com.vn/" class="text-gray m-r-15 huongdansudung">

                <span nz-icon nzType="question-circle" nzTheme="outline"></span>
                Hướng dẫn sử dụng
            </a>
            <a href="javascript:void(0);" class="text-gray m-r-15">&copy; 2025 | SandboxVN</a>
        </span>
    </div>
</footer>
