import { REGEX } from './../../../constants/config';

import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ResponseData } from 'src/app/models/response';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MESSAGE } from 'src/app/constants/message';
import { UserService } from 'src/app/services/core/user.service';

@Component({
  selector: 'app-form-user-changepass',
  templateUrl: './form-user-changepass.component.html',
  styleUrls: ['./form-user-changepass.component.css'],
})
export class FormUserChangePassComponent implements OnInit {
  @Input() fromChatPage:boolean=false;
  isLoading = false;
  id: any;
  isExtension:boolean=false;
  formInput: FormGroup;

  passwordVisible = false;
  listOfRole = [];

  modelUser: any = {};
  modelDonVi: any = {};
  modelUserName: string;

  constructor(
    private fb: FormBuilder,
    @Optional() private modal : NzModalRef,
    private _userService: UserService,
    private spinner: NgxSpinnerService,
    private _notification: NzNotificationService
  ) {}

  ngOnInit() {
    this.formInput = this.fb.group({
      oldPassword: [null, [Validators.required]],
      newPassword: [
        null,
        [Validators.required, Validators.pattern(REGEX.PASSWORD)],
      ],
      confirmPassword: [
        null,
        [Validators.required, Validators.pattern(REGEX.PASSWORD)],
      ],
    });
    this.isExtension = ((localStorage.getItem("isExtension")as unknown) as boolean)||this.fromChatPage;
  }

  onViewPass() {
    this.passwordVisible = !this.passwordVisible;
  }

  markFormGroupTouched(formGroup) {
    for (const i in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(i)) {
        formGroup.controls[i].markAsDirty();
        formGroup.controls[i].updateValueAndValidity();
      }
    }
  }

  onSave() {
    this.markFormGroupTouched(this.formInput);
    if (this.formInput.invalid) {
      return;
    }
    let model = this.formInput.value;
    this.spinner.show();
    this._userService.userDoiMatKhau(model).then((res: ResponseData) => {
      this.spinner.hide();
      if (res.success) {
        this._notification.success(MESSAGE.SUCCESS, 'Cập nhật thành công');
        this.modal?.close(true);
      } else {
        this._notification.warning(MESSAGE.WARNING, res.message);
      }
    });
  }

  onThoat() {
    this.modal?.close(false);
  }
}
