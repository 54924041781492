<form nz-form [formGroup]="formInput">
  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24" nzFor="oldPassword"
      >Mật khẩu hiện tại</nz-form-label
    >
    <nz-form-control [nzLg]="fromChatPage ? 24 : 14" [nzSm]="14" [nzXs]="24" [nzErrorTip]="phoneErrorTpl">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input
          nz-input
          formControlName="oldPassword"
          [type]="passwordVisible ? 'text' : 'password'"
        />
      </nz-input-group>
      <ng-template #phoneErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')"
          >Không được bỏ trống!</ng-container
        >
      </ng-template>
      <ng-template #pricePrefix>
        <span
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="onViewPass()"
        ></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24" nzFor="newPassword"
      >Mật khẩu mới</nz-form-label
    >
    <nz-form-control [nzLg]="fromChatPage ? 24 : 14" [nzSm]="14" [nzXs]="24" [nzErrorTip]="phoneErrorTpl1">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input
          nz-input
          formControlName="newPassword"
          [type]="passwordVisible ? 'text' : 'password'"
        />
      </nz-input-group>
      <ng-template #phoneErrorTpl1 let-control>
        <ng-container *ngIf="control.hasError('required')"
          >Không được bỏ trống!</ng-container
        >
        <ng-container *ngIf="control.hasError('pattern')">
          Ít nhất 8 ký tự.<br />
          Ít nhất một ký tự chữ thường.<br />
          Ít nhất một ký tự chữ in hoa.<br />
          Ít nhất một chữ số.<br />
          Ít nhất một ký tự đặc biệt từ danh sách [#?!&#64;$%^&*-].<br />
        </ng-container>
      </ng-template>
      <ng-template #pricePrefix>
        <span
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="onViewPass()"
        ></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label [ngStyle]="{'text-align': 'left'}" [nzLg]="fromChatPage ? 24 : 6" nzRequired [nzSm]="6" [nzXs]="24" nzFor="confirmPassword"
      >Nhập lại mật khẩu mới</nz-form-label
    >
    <nz-form-control [nzLg]="fromChatPage ? 24 : 14" [nzSm]="14" [nzXs]="24" [nzErrorTip]="phoneErrorTpl2">
      <nz-input-group [nzSize]="'small'" [nzSuffix]="pricePrefix">
        <input
          nz-input
          formControlName="confirmPassword"
          [type]="passwordVisible ? 'text' : 'password'"
        />
      </nz-input-group>
      <ng-template #phoneErrorTpl2 let-control>
        <ng-container *ngIf="control.hasError('required')"
          >Không được bỏ trống!</ng-container
        >
        <ng-container *ngIf="control.hasError('pattern')">
          Ít nhất 8 ký tự.<br />
          Ít nhất một ký tự chữ thường.<br />
          Ít nhất một ký tự chữ in hoa.<br />
          Ít nhất một chữ số.<br />
          Ít nhất một ký tự đặc biệt từ danh sách [#?!&#64;$%^&*-].<br />
        </ng-container>
      </ng-template>
      <ng-template #pricePrefix>
        <span
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="onViewPass()"
        ></span>
      </ng-template>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item nz-row class="register-area">
    <nz-form-control [nzSpan]="14" [nzOffset]="6" [nzXs]="{ span: 14, offset: 0 }">
      <button
        nz-button [nzSize]="'small'"
        nzType="primary"
        class="m-r-10"
        [disabled]="!formInput.valid"
        (click)="onSave()"
      >
        Lưu
      </button>
      <button *ngIf="!isExtension" [nzSize]="'small'" nz-button (click)="onThoat()">Thoát</button>
    </nz-form-control>
  </nz-form-item>
</form>
