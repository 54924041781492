import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SharedModule } from '../shared.module';

import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';

import { HeaderComponent } from "./header/header.component";
import { QuickViewComponent } from './quick-view/quick-view.component';
import { SideNavComponent } from "./side-nav/side-nav.component";
import { FooterComponent } from "./footer/footer.component";

import { SideNavDirective } from "../directives/side-nav.directive";
import { ThemeConstantService } from '../services/theme-constant.service';
import { FormUserInfoComponent } from './form-user-info/form-user-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { ComponentsModule } from 'src/app/components/components.module';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { FormUserChangePassComponent } from './form-user-changepass/form-user-changepass.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzImageModule } from 'ng-zorro-antd/image';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { IconGuideComponent } from './icon-guide/icon-guide.component';
import { ChonTkComponent } from './form-user-info/chon-tk/chon-tk.component';
import { NzTableModule } from 'ng-zorro-antd/table';
import { SbMenuTabComponent } from './sb-menu-tab/sb-menu-tab.component';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

const antdModule = [
  NzAvatarModule,
  NzBadgeModule,
  NzRadioModule,
  NzDropDownModule,
  NzListModule,
  NzDrawerModule,
  NzDividerModule,
  NzSwitchModule,
  NzInputModule,
  NzButtonModule,
  NzCardModule,
  NzTypographyModule,
  NzSkeletonModule,
  NzTagModule,
  NzFormModule,
  NzSelectModule,
  NzInputModule,
  NzDividerModule,
  NzCheckboxModule,
  NzImageModule,
  NzTableModule,
  NzPopoverModule
]

@NgModule({
  exports: [
    CommonModule,
    HeaderComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    SideMenuComponent,
    FormUserInfoComponent,
    FormUserChangePassComponent,
    IconGuideComponent,
    SbMenuTabComponent
  ],
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CommonModule,
    ComponentsModule,
    SharedModule,
    ...antdModule
  ],
  declarations: [
    HeaderComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent,
    FormUserInfoComponent,
    FormUserChangePassComponent,
    SideMenuComponent,
    IconGuideComponent,
    ChonTkComponent,
    SbMenuTabComponent
  ],
  providers: [
    ThemeConstantService
  ]
})

export class TemplateModule { }
