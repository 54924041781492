export type DiscountType = {
  id: number;
  ten: string;
};

export const LstKieuGiamGia: DiscountType[] = [
  {
    id: 1,
    ten: '%',
  },
  {
    id: 2,
    ten: 'đ',
  },
];
export const TypeChiTietDonHang = {
  SanPham: 1,
  DichVu: 2,
  Combo: 3,
  The: 4,
  NapThe: 5,
  ThuKhac: 6,
};
export const LstKieuGiamGiaDong: DiscountType[] = [
  {
    id: 2,
    ten: 'đ',
  },
];

//hoa hồng
export const KIEULOAI_APDUNG_HOAHONG = {
  DichVu: 2,
  SanPham: 1,
  The: 4,
};

export const KIEULOAI_GIATRI = {
  PHAN_TRAM: 1,
  TIEN_TE: 2,
};

export const KIEULOAI_HOAHONG = {
  MARKETING: 1,
  //   BAN_HANG: 2,
  THUC_HIEN: 3,
  UPSALE: 4,
  //    THU_NGAN: 5,
};

//Loại thưởng phạt
export const LOAI_THUONG_PHAT = {
  THUONG: 1,
  PHAT: 2,
  PHU_CAP: 3,
  TAM_UNG: 4,
  TAM_GIU_LUONG: 5,
};

//Lương nhân viên
export const Enum_KieuLoaiTinhLuong = {
  LuongCoBanVaHoaHong: 1,
  LuongCoBan: 2,
  HoaHong: 3,
};
export const Enum_KieuLoaiLuong = {
  LuongTheoGio: 1,
  LuongTheoNgay: 2,
  LuongTheoThang: 3,
};

export const MaTienIchSCRM = {
  TI_ThongBao: 'TI_ThongBao',
  TI_HeThong: 'TI_HeThong',
  TI_Chung: 'TI_Chung',
  TI_Booking: 'TI_Booking',
  TI_Branch: 'TI_Branch',
  TI_Contact: 'TI_Contact',
  TI_Customer: 'TI_Customer',
  TI_Order: 'TI_Order',
  TI_Switchboard: 'TI_Switchboard',
  TI_TongDai_SimSo: 'TI_TongDai_SimSo',
  TI_Landingpage: 'TI_Landingpage',
  TI_Kho: 'TI_Kho',
  TI_ThanhToan: 'TI_ThanhToan',
  TI_SMS: 'TI_SMS',
  TI_NhomKhachHang: 'TI_NhomKhachHang',
  TI_HangKhachHang: 'TI_HangKhachHang',
  TI_Voucher: 'TI_Voucher',
  TI_KhuyenMai: 'TI_KhuyenMai',
  TI_ChamCong: 'TI_ChamCong',
  TI_ThuChi: 'TI_ThuChi',
  TI_HoaHongGioiThieu: 'TI_HoaHongGioiThieu',
  TI_OrderLogistic: 'TI_OrderLogistic',
  TI_PaymentOnline: 'TI_PaymentOnline',
};
export const PhanLoaiXuat = {
  XuatTHNCC: 1,
  XuatChoTang: 2,
  XuatDongGoi: 3,
  XuatHang: 4,
  XuatTieuHao: 5,
  XuatHuyViHong: 6,
  XuatKhac: 7,
  XuatDaoTao: 8,
  XuatSuDung: 9,
  XuatThuNgan: 10,
  XuatBanHangGiaoVan: 11,
};

export enum TypeAddLandingData {
  landing = 0, //Cấp 1
  utm_Campaign = 2, //Cấp 3
}

export const DonHangLogistic_TrangThaiChotDonEnum = {
  ChuaChot: 0,
  DaChot: 1,
  DangChotDon: 2,
};
export const KieuNgayContact = [
  { ten: 'Ngày tạo', id: 'NgayTao' },
  { ten: 'Ngày sale nhận data', id: 'SaleNgayNhanData' },
  { ten: 'Ngày sale tác nghiệp', id: 'SaleTacNghiepNgayCapNhat' },
  { ten: 'Ngày sale tác nghiệp tiếp', id: 'SaleTacNghiepTiepNgayBatDau' },
  { ten: 'Ngày chốt Đơn hàng', id: 'DonHangNgayChot' },
  { ten: 'Ngày nhận Care đơn', id: 'CareDonNgayNhan' },
  { ten: 'Ngày tác nghiệp care đơn', id: 'CareDonNgayTacNghiep' },
  { ten: 'Ngày đăng đơn', id: 'NgayDangDon' },
  { ten: 'Ngày giao hàng', id: 'GiaoHangNgayGiaoHang' },
  { ten: 'Ngày cập nhật trạng thái GH', id: 'GiaoHangTrangThaiNgayCapNhat' },
  { ten: 'Ngày đối soát', id: 'TrangThaiDoiSoatNgay' },
];

export const KieuNgayBaoCaoCuaHang = [
  { ten: 'Ngày thanh toán', id: 1 },
  { ten: 'Ngày tạo', id: 2 },
  { ten: 'Ngày đối soát', id: 3 },
];

export const ROLE_HT = {
  DV_SALE: 'DV_SALE',
  DV_SALE_LEAD: 'DV_SALE_LEAD',
};

export const ChuyenCSKHKhi_Enum = [
  { ten: 'Đếm ngược CSKH khi đăng đơn', id: 1 },
  { ten: 'Đếm ngược CSKH khi chốt đơn', id: 2 },
  { ten: 'Đếm ngược CSKH theo cấu hình giao hàng', id: 3 },
];

export const TypeStopStartEnum = {
  Start: 1,
  Stop: 2,
};

export const MaNganhNghe = {
  BI_A: 'BI-A',
};

export const RoleCode = {
  HT_NGUOI_GIOI_THIEU: 'HT_NGUOI_GIOI_THIEU',
  DV_SALE: 'DV_SALE',
  DV_QL_KHO: 'DV_QL_KHO',
};

export const EnumLoaiDonHangNhanVienHanhDong = {
  //Tư vấn
  TuVan: 1,
  //Bán hàng
  BanHang: 2,
  //Thực hiện
  ThucHien: 3,
  //Upsales
  Upsales: 4,
  //Thu ngân
  ThuNgan: 5,
};

export const EnumHightLightStatus = {
  Success: 1,
  Warning: 2,
  Danger: 3,
};
export const MaNganhNghePhucVu = {
  NGANH_NGHE_PHUCVU_CO_MAN_HINH_TINH_GIO:
    'NGANH_NGHE_PHUCVU_CO_MAN_HINH_TINH_GIO',
};

export enum EnumLoaiKetNoi {
  Facebook = 1,
  Landing = 2,
  Website = 3,
  PartnerShip = 4,
  TongDai = 5,
}
