import { ChiaSoCareDonModule } from './pages/chia-so-care-don/chia-so-care-don.module';
import { ChiaSoModule } from './pages/chia-so/chia-so.module';
import { ThongTinDatLichModule } from './pages/view-thong-tin-dat-lich/thong-tin-dat-lich.module';
import { AuthChildrenGuard } from './guard/authChildren.guard';
import { AuthGuard } from './guard/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { CommonLayoutComponent } from './layout/common-layout/common-layout.component';
import { ExtensionLayoutComponent } from './layout/extension-layout/extension-layout.component';

const routes: Routes = [
  {
    path: 'register',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/end-user/end-user.module').then((m) => m.EndUserModule),
  },
  {
    path: 'forgot-password',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'view-bi-a',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/order-end-user/order-end-user.module').then(
        (m) => m.OrderEndUserModule
      ),
  },
  {
    path: 'dat-lich',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/booking-end-user/booking-end-user.module').then(
        (m) => m.BookingEndUserModule
      ),
  },
  {
    path: 'dat-lich/:idChiNhanh',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/booking-end-user/booking-end-user.module').then(
        (m) => m.BookingEndUserModule
      ),
  },

  {
    path: 'dat-lich-nang-cao',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/dat-lich-nang-cao/dat-lich-nang-cao.module').then(
        (m) => m.DatLichNangCaoModule
      ),
  },

  {
    path: 'login',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'warehouse-product',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/WarehouseProduct/warehouseProduct.module').then(
        (m) => m.WarehouseProductModule
      ),
  },

  {
    path: 'switchboard',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/switchboard/switchboard.module').then(
        (m) => m.SwitchboardModule
      ),
  },
  {
    path: 'customer',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/Customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'order',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/Order/order.module').then((m) => m.OrderModule),
  },
  {
    path: 'promotion',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/Promotion/promotion.module').then(
        (m) => m.PromotionModule
      ),
  },
  {
    path: 'major',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/major/major.module').then((m) => m.MajorModule),
  },
  {
    path: 'quantri',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/quantri/quantri.module').then((m) => m.QuantriModule),
  },
  {
    path: 'caidat',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/caidat-hethong/caidat-hethong.module').then(
        (m) => m.CaidatHethongModule
      ),
  },
  {
    path: 'booking',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/booking/booking.module').then((m) => m.BookingModule),
  },
  {
    path: 'dat-lich-ad',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/dat-lich/dat-lich.module').then((m) => m.DatLichModule),
  },
  {
    path: 'noti',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
  },
  {
    path: 'marketing',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
  },
  {
    path: 'chia-so',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/chia-so/chia-so.module').then((m) => m.ChiaSoModule),
  },
  {
    path: 'chathub',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/chatbox/chatbox.module').then((m) => m.ChatboxModule),
  },
  {
    path: 'report',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/Report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'dg/:maDanhGia',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/danh-gia/danhgia.module').then((m) => m.DanhGiaModule),
  },
  {
    path: 'v/:maDanhGia',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/view-thong-tin-dat-lich/thong-tin-dat-lich.module').then(
        (m) => m.ThongTinDatLichModule
      ),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'order-print',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/order-print/order-print.module').then(
        (m) => m.OrderPrintModule
      ),
  },
  {
    path: 'order-kh',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/order-khach-hang/order-khach-hang.module').then(
        (m) => m.OrderKhachHangModule
      ),
  },
  {
    path: 'guide',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/guide/guide.module').then((m) => m.GuideModule),
  },
  {
    path: 'website',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/website/website.module').then((m) => m.WebsiteModule),
  },
  {
    path: 'ceo-dashboard',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/dashboard-baocao/dashboard-baocao.module').then(
        (m) => m.DashboardBaoCaoModule
      ),
  },
  {
    path: 'danh-muc',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/danh-muc/danhmuc.module').then((m) => m.DanhMucModule),
  },
  {
    path: 'facebook',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/facebook/facebook.module').then((m) => m.FacebookModule),
  },
  {
    path: 'fb-login',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/facebook-login/facebook-login.module').then(
        (m) => m.FacebookLoginModule
      ),
  },
  {
    path: 'in-don',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./pages/in-don/in-don.module').then((m) => m.InDonModule),
  },
  {
    path: 'sale',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/sale/sale.module').then((m) => m.SaleModule),
  },
  {
    path: 'work',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/work/work.module').then((m) => m.WorkModule),
  },
  {
    path: 'ban-view',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./modules/grid-ban-view/grid-ban-view.module').then(
        (m) => m.GridBanViewModule
      ),
  },
  {
    path: 'webhookladi',
    // canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/webhookladi/webhookladi.module').then(
        (m) => m.WebHookLadiModule
      ),
  },
  {
    path: 'webhookfb',
    // canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/webhookfb/webhookfb.module').then(
        (m) => m.WebHookFBModule
      ),
  },
  {
    path: 'chia-so-care-don',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/chia-so-care-don/chia-so-care-don.module').then(
        (m) => m.ChiaSoCareDonModule
      ),
  },
  {
    path: 'extension',
    canActivate: [AuthGuard],
    component: ExtensionLayoutComponent,
    loadChildren: () =>
      import('./modules/extension/extension.module').then(
        (m) => m.ExtensionModule
      ),
  },
  {
    path: 'delivery',
    // canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/delivery/logdelivery.module').then(
        (m) => m.DeliveryModule
      ),
  },
  {
    path: 'ecommerce',
    // canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/ecommerce/logecommerce.module').then(
        (m) => m.LogEcommerceModule
      ),
  },
  {
    path: 'marketing-campaign',
    canActivate: [AuthGuard],
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/marketing-campaign/marketing-campaign.module').then(
        (m) => m.MarketingCampaignModule
      ),
  },
  {
    path: '**',
    component: CommonLayoutComponent,
    loadChildren: () =>
      import('./pages/caidat-hethong/caidat-hethong.module').then(
        (m) => m.CaidatHethongModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
