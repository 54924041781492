import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuConstantService {

  // Theme Config
  isMenu: Array<any> = [];
  static id: string = null;
  static idParent: string = null;


  private currentMenu = new BehaviorSubject(this.isMenu);
  isMenuChanges = this.currentMenu.asObservable();

  setPageChoose(id: string, idParent: string) {
    MenuConstantService.id = id;
    MenuConstantService.idParent = idParent;
  }

  getPageChoose() {
    return { id: MenuConstantService.id, idParent: MenuConstantService.idParent };
  }

  changeMenuLoad(menu: Array<any>) {
    this.currentMenu.next(menu);
  }
}
