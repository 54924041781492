<div id="screen-main">
    <div class="page-container">
        <div class="main-content" style="padding: 5px;">
            <ul class="menu-tab row"  *ngIf="showLayout">
                <li routerLinkActive="active-link">
                    <a [routerLink]="['/extension/chot-don']" >
                        Nhập đơn mới
                    </a>  
                </li>
                <li  routerLinkActive="active-link">
                    <a [routerLink]="['/extension/danh-sach-don']">
                        Danh sách đơn
             
                    </a>  
                </li>
                <li  routerLinkActive="active-link">
                    <a [routerLink]="['/extension/danh-sach-contact']">
                        Danh sách contact
                    </a>  
                </li>
                <li routerLinkActive="active-link">
                    <a [routerLink]="['/extension/thiet-lap']" >
                        Thiết lập
                    </a>  
                </li>
            </ul>
            <router-outlet></router-outlet>
        </div>
    </div>

</div>
<!-- <ngx-spinner [fullScreen]="true" type="ball-clip-rotate-multiple" size="medium">
<p style="font-size: 20px; color: white">Đang tải...</p>
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.1)" [fullScreen]="false" type="ball-scale-multiple" size="medium"
template="<img style='width:150px' src='assets/gif/loading.gif' />">
</ngx-spinner>